/* ./src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.disable-scrollbar::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
  }
      
.disable-scrollbar {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE 10+ */
}